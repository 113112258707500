import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import {FetchMinMaxTempDataSuccess} from '../../states/utilState'

export const fetchMinMaxTempData = createAsyncThunk(
    'minMaxData/fetchMinMaxTempData',
    async () => {
        const url: string = "https://jsq305drm9.execute-api.ap-northeast-1.amazonaws.com/version1/minmax/3"
        try{
            return await (await axios.get<FetchMinMaxTempDataSuccess>(url)).data
        } catch(err){
            console.log(err)
        }
    }
)