import React from 'react'

import {
    Wrapper, HeaderWrapper, LogoAreaWrapper, LoginTitleWrapper
} from './styledComponents'
import LoginFormBlock from '../../Organisms/LoginFormBlock'

const LoginTemplate: React.FC = () => {

    return (
        <Wrapper >
            <HeaderWrapper>
                <LogoAreaWrapper></LogoAreaWrapper>
                <LoginTitleWrapper>環境センサー</LoginTitleWrapper>
            </HeaderWrapper>

            <LoginFormBlock></LoginFormBlock>

        </Wrapper>
    )

}

export default LoginTemplate