import styled from "styled-components"

export const SensorBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 94vw;
  @media screen and (orientation: portrait) {
    height: 22vw;
  }
  @media screen and (orientation: landscape) {
    height: 9vw;
  }
  &:not(:first-child) {
    border-top:#666 1px solid;
  }
`

