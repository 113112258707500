import styled from "styled-components"

export const DateTimeWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 0;
  color: #666;
  margin: 0;
  font-weight: 700;
  line-height: 1;
  @media screen and (orientation: portrait) {
    left: 0;
    width: 100vw;
    font-size: 4.5vw;
    text-align: center;
  }
  @media screen and (orientation: landscape) {
    right: 0;
    height: 2.8vw;
    font-size: 2.8vw;
    padding: 0 1.5vw;
  }

  & > span.dow {
    @media screen and (orientation: portrait) {
      font-size: 3.5vw;
    }
    @media screen and (orientation: landscape) {
      font-size: 1.8vw;
    }
  }
`
