import { DateTimeWrapper } from "./styledComponents"

const DateSpanBlock:React.FC<{start:Date, end:Date}> = (props) => {

    return (
        <DateTimeWrapper>
            {props.start.getFullYear()}/{('0' + (props.start.getMonth() + 1)).slice(-2)}/{('0' + (props.start.getDate())).slice(-2)}〜
            {props.end.getFullYear()}/{('0' + (props.end.getMonth() + 1)).slice(-2)}/{('0' + (props.end.getDate())).slice(-2)}
        </DateTimeWrapper>
    )
}

export default DateSpanBlock
