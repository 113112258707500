import {
    ModalMenuWrapper, ModalMenu, ModalMenuItem, ModalMenuIcon, ModalMenuText
} from "./styledComponents"
import { useNavigate, useLocation } from 'react-router-dom'
import { TimeIconImage, MonthIconImage, TodayIconImage, LogoutIconImage } from "../../../style/image"
import React, { useContext } from 'react'
import { LoggedInContext, AuthInfoContext } from "../../../context/AuthContextProvider";

const ModalMenuContent:React.FC<{init: boolean, open: boolean, closeHandler:()=>void}> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    /* eslint-disable */
    const [isLoggedIn, setLoggedIn] = useContext(LoggedInContext);
    const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
    /* eslint-enable */

    const menuNavigation = (path: string) => {
        if (!props.init && props.open && location.pathname === path) {
            props.closeHandler();
        } else if (!props.init && location.pathname !== path) {
            navigate(path);
        }
    }

    const logout = () => {
        setAuthInfo({company: "", userid: ""});
        setLoggedIn({isLogin: false});
        navigate("/login");
    }

    return (
        <ModalMenuWrapper>
            <ModalMenu>

                <ModalMenuItem onClick={() => menuNavigation("/")}>
                    <ModalMenuIcon><TimeIconImage /></ModalMenuIcon>
                    <ModalMenuText>現在の環境</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem onClick={() => menuNavigation("/detail")}>
                    <ModalMenuIcon><MonthIconImage /></ModalMenuIcon>
                    <ModalMenuText>月ごとの環境（環境評価-月）</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem onClick={() => menuNavigation("/detail")}>
                    <ModalMenuIcon><TodayIconImage /></ModalMenuIcon>
                    <ModalMenuText>日ごとの環境（環境評価-日）</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem onClick={logout}>
                    <ModalMenuIcon><LogoutIconImage /></ModalMenuIcon>
                    <ModalMenuText>ログアウト</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />

            </ModalMenu>
        </ModalMenuWrapper>
    )
}

export default ModalMenuContent
