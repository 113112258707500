import styled from "styled-components"

export const SensorNameWrapper = styled.div`
  position: relative;
  line-height: 1.1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #666;
  width: 25vw;
  font-weight: 500;
  @media screen and (orientation: portrait) {
    height: 22vw;
    font-size: 4.2vw;
  }
  @media screen and (orientation: landscape) {
    height: 9vw;
    font-size: 2.4vw;
  }
`
