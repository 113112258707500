import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Wrapper, HeaderWrapper, LogoAreaWrapper, WeatherWrapper, EnvDateWrapper, EnvTitleWrapper, TabContainer, Tab } from './styledComponents'
import SensorContainer from '../../Organisms/ContainerBlock'
import DateTimeBlock from '../../Organisms/DateTimeBlock'
import ModalBlock from '../../Organisms/ModalBlock'

import { SensorData, FetchMinMaxTempData } from '../../../states/utilState'
import { useOfficeDataState } from '../../../reducer/officeData/selectors'
import { useDispatch } from 'react-redux'
import { fetchOfficeData } from '../../../reducer/officeData/asyncAction'
import { SensorListInfo, SensorListContext } from '../../../context/AuthContextProvider'

import { useMinMaxTempDataState } from '../../../reducer/minMaxData/selectors'
import { fetchMinMaxTempData } from '../../../reducer/minMaxData/asyncAction'

const MainTemplate: React.FC<{sensors: SensorListInfo}> = (props) => {

    const officeDataState = useOfficeDataState().officeData
    /* eslint-disable */
    const [sensors, setSensors] = React.useContext(SensorListContext);
    const [officeData, setofficeData] = useState<SensorData[]>()
    const [placeAmount, setPlaceAmount] = useState(0)
    const [sensorNameList, setSensorNameList] = React.useContext(SensorListContext);
    const [resizeFired, setResize] = useState(true);
    /* eslint-enable */

    const getSensorNameList = () => {
        if (officeDataState.isDataexist === false) {
            console.log("officeData undefined. return.")
            return;
        }
        if (sensorNameList.sensorNames.length > 0) {
            console.log(sensorNameList);
            console.log(props.sensors.sensorNames);
            console.log("sensorNameList.length: " + sensorNameList.sensorNames.length)
            return;
        }
        let list = [];
        const limit: number = officeDataState.sensorDatas.length > 5 ? 5 : officeDataState.sensorDatas.length;
        for (let i: number = 0; i < limit; i++) {
             list.push(officeDataState.sensorDatas[i].placeName);
        }
        setSensorNameList({sensorNames: list});
        console.log(sensorNameList);
    }
    const dispatch = useDispatch()
    var timer: NodeJS.Timeout
    var timer2: NodeJS.Timeout
    const polling = () => {
        timer = setTimeout(()=>{
            dispatch(fetchOfficeData())
            polling()
            setofficeData([...officeDataState.sensorDatas])
            setPlaceAmount(officeDataState.sensorDatas.length)
        }, 60 * 1000)
    }
    useEffect(()=>{
        dispatch(fetchOfficeData())
        polling()
        getSensorNameList()
        return ()=> clearTimeout(timer)
    },[setofficeData,officeDataState.isDataexist])  // eslint-disable-line

    const minMaxDataState = useMinMaxTempDataState().minMaxData
    /* eslint-disable */
    const [minMaxData, setMinMaxData] = useState<FetchMinMaxTempData>()
    /* eslint-enable */
    
    const minMaxPolling = () => {
        timer2 = setTimeout(()=>{
            dispatch(fetchMinMaxTempData())
            minMaxPolling()
            setMinMaxData(minMaxDataState.temp)
        }, 60 * 60 * 1000)
    }
    useEffect(()=>{
        dispatch(fetchMinMaxTempData())
        minMaxPolling()
        return ()=> clearTimeout(timer2)
    },[setMinMaxData,minMaxDataState.isDataexist])  // eslint-disable-line

    const setScrollHeight = () => {
        const innerHeight = window.innerHeight;
        console.log("innerHeight: " + innerHeight);
        let container: HTMLElement | null = document.getElementById("sensor-scroll");
        let header: HTMLElement | null = document.getElementById("sensor-header");
        let footer: HTMLElement | null = document.getElementById("sensor-footer");
        if (container && header && footer) {
            const headerHeight: number = Number(header.clientHeight);
            const footerHeight: number = Number(footer.clientHeight);
            const frameHeight: number = innerHeight - headerHeight - footerHeight;
            console.log("headerHeight: " + headerHeight);
            console.log("footerHeight: " + footerHeight);
            console.log("frameheight: " + frameHeight);

            container.style.height = frameHeight + "px";
            container.style.top = headerHeight + "px";
            setResize(false);
        }
    }

    window.addEventListener('resize', () => {
        setTimeout(() => {
            setResize(true);
        }, 150);
    })
    window.addEventListener('orientationchanged', () => {
        setTimeout(() => {
            setResize(true);
        }, 150);
    })
    useEffect(() => {
        setScrollHeight();
    }, [resizeFired])

    return (
        <Wrapper >
            <HeaderWrapper id={"sensor-header"}>
                <LogoAreaWrapper></LogoAreaWrapper>
                <WeatherWrapper>{minMaxDataState.temp?.addr}　{minMaxDataState.temp?.max}℃/{minMaxDataState.temp?.min}℃</WeatherWrapper>
                <TabContainer>
                    <Tab className="left active">現在の環境</Tab>
                    <Link to="/detail"><Tab className="right">環境評価振り返り</Tab></Link>
                </TabContainer>
                <EnvDateWrapper>
                    <EnvTitleWrapper>現在の環境</EnvTitleWrapper>
                    <DateTimeBlock />
                </EnvDateWrapper>
            </HeaderWrapper>

            <SensorContainer sensorData={officeDataState.sensorDatas} />

            <ModalBlock />

        </Wrapper>
    )
}

export default MainTemplate