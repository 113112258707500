import CalendarCell from "../../Atoms/CalendarCell"

const CalendarDays: React.FC<{start: Date, end: Date, onDateClick:(event:React.MouseEvent<HTMLDivElement>)=>void}> = (props) => {
    return (<>
        {makeCalendarDays(props.start, props.end, props.onDateClick)}
    </>)
}

type CalendarDate = {
    day: number;
    fulldate: string;
}

const makeCalendarDays = (start: Date, end: Date, onDateClick:(event:React.MouseEvent<HTMLDivElement>)=>void) => {
    const startDow = start.getDay();
    let dates: CalendarDate[] = [];
    for (let i:number = 0; i < startDow; i++) {
        const date: CalendarDate = {day: -1, fulldate: ""};
        dates.push(date);
    }
    for (let i:number = 1; i <= end.getDate(); i++) {
        const date: CalendarDate = {day: i, fulldate: start.getFullYear() + "-" + (('0' + (start.getMonth() + 1))).slice(-2) + "-" + (('0' + i).slice(-2))};
        dates.push(date);
    }
    for (let i:number = dates.length; i < 42; i++) {
        const date: CalendarDate = {day: -1, fulldate: ""};
        dates.push(date);
    }
    
    return dates.map((day, index) => {
        if (day.day === -1) {
            return (
                <CalendarCell dow={index} key={index}></CalendarCell>
            )
        } else {
            return (
                <CalendarCell data={day.day.toString()} dow={index % 7} attrDate={day.fulldate} key={index} onDateClick={onDateClick}></CalendarCell>
            )
        }
    })
}
export default CalendarDays

