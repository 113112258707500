import React, { useEffect, useState } from 'react'
import { CalendarContainerWrapper, CalendarScrollWrapper, CalendarContentWrapper, MonthSelectWrapper, CalendarContainerInnerWrapper, MonthWrapper, MonthSelectorWrapper } from "./styledComponents"
import { ContainerFrame } from "../ContainerBlock/styledComponents"
import { NavBackBtnImage, NavForwardBtnImage } from "../../../style/image"
import CalendarWeek from "../../Molecules/CalendarWeek"
import CalendarDays from "../../Molecules/CalendarDays"

const CalendarContainer:React.FC<
    {
        date: Date,
        onDateClick:(event:React.MouseEvent<HTMLDivElement>) => void,
        onPrevMonthClick:(event:React.MouseEvent<HTMLImageElement>) => void,
        onNextMonthClick:(event:React.MouseEvent<HTMLImageElement>) => void,
    }
    > = (props) => {
    const [firstDate] = useState(new Date(props.date.getFullYear(), props.date.getMonth(), 1))
    const [lastDate] = useState(new Date(props.date.getFullYear(), props.date.getMonth() + 1, 0))
    const [fwdClass, setFwdClass] = useState("forward")

    const displayNextMonthButton = (firstDate: Date) => {
        const today: Date = new Date();
        const firstDay: Date = new Date(firstDate);
        const lastDay: Date = new Date(lastDate);
        if (firstDay.getTime() <= today.getTime() && today.getTime() <= lastDay.getTime()) {
            setFwdClass("forward end");
            console.log("forward end");
        } else {
            setFwdClass("forward");
            console.log("forward");
        }
    }

    useEffect(() => {
        displayNextMonthButton(firstDate)
    // eslint-disable-next-line
    }, [firstDate, fwdClass])

    return (
        <ContainerFrame id={"sensor-scroll"}>
            <CalendarContainerWrapper>
                <CalendarScrollWrapper>
                    <CalendarContentWrapper>
                        <MonthSelectWrapper>
                            <MonthWrapper>{firstDate.getFullYear() + "." + (('0' + (firstDate.getMonth() + 1)).slice(-2))}</MonthWrapper>
                            <MonthSelectorWrapper id={"cal-selector-buttons"}>
                                <NavBackBtnImage onClick={props.onPrevMonthClick}/>
                                <NavForwardBtnImage className={fwdClass} onClick={props.onNextMonthClick}/>
                            </MonthSelectorWrapper>
                        </MonthSelectWrapper>

                        <CalendarContainerInnerWrapper>
                            <CalendarWeek></CalendarWeek>
                            <CalendarDays start={firstDate} end={lastDate} onDateClick={props.onDateClick}></CalendarDays>
                        </CalendarContainerInnerWrapper>

                    </CalendarContentWrapper>
                </CalendarScrollWrapper>
            </CalendarContainerWrapper>
        </ContainerFrame>
    )
}

export default CalendarContainer
