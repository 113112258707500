import styled from 'styled-components';
export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0;
  margin: 0;
  font-family: 'M PLUS Rounded 1c', helvetica;
  @media screen and (orientation: portrait) {
    height: 100vh;
  }
  @media screen and (orientation: landscape) {
    height: 100vw;
  }
`

export const HeaderWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: space-between;
`

export const LogoAreaWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5vw;
  height: 10vh;
`

export const LoginTitleWrapper = styled.div`
  box-sizing: content-box;
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: 1001;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: cadetblue;
  @media screen and (orientation: portrait) {
    top: 13vh;
    width: 100vw;
    height: 8vw;
    font-size: 8vw;
  }
  @media screen and (orientation: landscape) {
    top: 12vh;
    width: 100vw;
    height: 4vw;
    font-size: 4vw;
  }
`
