import React from 'react'
import { Navigate } from "react-router-dom"
import MainTemplate from '../Template/MainTemplate/MainTemplate'
import { LoginInfo, SensorListInfo } from "../../context/AuthContextProvider";

const Main: React.FC<{login: LoginInfo, sensors: SensorListInfo}> = (props) => {
    if (!props.login.isLogin) {
        return (
            <Navigate to="/login" replace></Navigate>  
        )
    } else {
        return (
            <MainTemplate sensors={props.sensors}>
            </MainTemplate>
        )
    }
}

export default Main