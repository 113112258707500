import {
    DisplayDataBlockWrapper, DisplayDataWrapper, SensorIconWrapper, SensorDataWrapper
} from "./styledComponents"
import { TempIcon, HumIcon, CO2Icon, LightIcon, SoundIcon } from '../../Atoms/Image'
import { SensorData } from "../../../states/utilState"


const DisplayDataBlock:React.FC<{sensorData: SensorData}> = (props) => {
    let strVol: string;
    let strLight: string;
    let strCO2: string;

    if (props.sensorData.volume.db < 0) {
        strVol = "-";
    } else {
        strVol = props.sensorData.volume.db.toString();
    }

    if (props.sensorData.light.lx < 0) {
        strLight = "-";
    } else {
        strLight = props.sensorData.light.lx.toString();
    }

    if (props.sensorData.co2.ppm < 0) {
        strCO2 = "-";
    } else {
        strCO2 = props.sensorData.co2.ppm.toString();
    }

    return (
        <DisplayDataBlockWrapper>

            <DisplayDataWrapper key={1001}>
                <SensorIconWrapper key={2001}><TempIcon level={props.sensorData.temp.level}></TempIcon></SensorIconWrapper>
                <SensorDataWrapper key={3001}>{props.sensorData.temp.temp}<span className="unit">℃</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper key={1002}>
                <SensorIconWrapper key={2002}><HumIcon level={props.sensorData.hum.level}></HumIcon></SensorIconWrapper>
                <SensorDataWrapper key={3002}>{props.sensorData.hum.hum}<span className="unit">%</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper key={1003}>
                <SensorIconWrapper key={2003}><SoundIcon level={props.sensorData.volume.level}></SoundIcon></SensorIconWrapper>
                <SensorDataWrapper key={3003}>{strVol}<span className="unit">dB</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper key={1004}>
                <SensorIconWrapper key={2004}><LightIcon level={props.sensorData.light.level}></LightIcon></SensorIconWrapper>
                <SensorDataWrapper key={3004}>{strLight}<span className="unit">lx</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper key={1005}>
                <SensorIconWrapper key={2005}><CO2Icon level={props.sensorData.co2.level}></CO2Icon></SensorIconWrapper>
                <SensorDataWrapper key={3005}>{strCO2}<span className="unit">ppm</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper key={1006}>
            </DisplayDataWrapper>

        </DisplayDataBlockWrapper>
    )
}

export default DisplayDataBlock

