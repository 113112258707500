import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import {EvaluationDataState, EvaluationData, FetchEvaluationDataSuccess} from '../../states/utilState'

export const fetchEvaluationData = createAsyncThunk(
    'evaluationData/fetchEvaluationData',
    async (date: string) => {
        const url: string = "https://jsq305drm9.execute-api.ap-northeast-1.amazonaws.com/version1/evaluation/3/" + date
        try{
            const result: FetchEvaluationDataSuccess[] = await (await axios.get<FetchEvaluationDataSuccess[]>(url)).data
            const evaluations: EvaluationData[] = result.map((data: FetchEvaluationDataSuccess) => {
                return {
                    placeName: data.placeName,
                    good: data.good,
                    normal: data.normal,
                    poor: data.poor,
                    available: data.available,
                }
            })
            const ret: EvaluationDataState = {
                evaluations: evaluations,
                isDataexist: true,
            }
            return ret;
        } catch(err){
            console.log(err)
        }
    }
)