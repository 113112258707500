import React from 'react'
import { ContainerFrame, ContainerWrapper, ContainerScrollWrapper } from "./styledComponents"
import { SensorBlockWrapper } from "../SensorBlock/styledComponents"
import SensorName from "../../Molecules/SensorName/index"
import Evaluation from "../../Molecules/Evaluation/index"
import DisplayDataBlock from "../../Molecules/DisplayData/index"
import { SensorData } from "../../../states/utilState"


const SensorContainer:React.FC<{sensorData: SensorData[]}> = (props) => {
    return (
        <ContainerFrame id={"sensor-scroll"}>
            <ContainerWrapper key={0} >
                <ContainerScrollWrapper>
                    {makeSensorBlockComponent(props.sensorData)}
                </ContainerScrollWrapper>
            </ContainerWrapper>
        </ContainerFrame>
    )
}

export default SensorContainer

const makeSensorBlockComponent = (datas: SensorData[]) => {
    return datas.map((data, index)=> {
        if (index < 5) {
            return (
                <SensorBlockWrapper key={index + 1}>
                    <SensorName placeName={data.placeName} key={101 + index}></SensorName>
                    <Evaluation level={data.evaluation} key={201 + index}></Evaluation>
                    <DisplayDataBlock sensorData={data} key={301 + index}></DisplayDataBlock>
                </SensorBlockWrapper>
            )
        } else {
            return (<></>);
        }
    })
}
