import {
    EvaluationWrapper
} from "./styledComponents"
import { EvaluationIconImage } from "../../../style/image"

const Evaluation:React.FC<{level: number}> = (props) => {
    return (
        <EvaluationWrapper><EvaluationIconImage level={props.level}></EvaluationIconImage></EvaluationWrapper>
    )
}

export default Evaluation

