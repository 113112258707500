import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import {SensorGraphDataState, SensorGraphData, FetchSensorGraphDataSuccess} from '../../states/utilState'

export const fetchSensorGraphData = createAsyncThunk(
    'sensorGraphData/fetchSensorGraphData',
    async (date: string) => {
        const url: string = "https://jsq305drm9.execute-api.ap-northeast-1.amazonaws.com/version1/sensor_graph/3/" + date
        try{
            const result: FetchSensorGraphDataSuccess = await (await axios.get<FetchSensorGraphDataSuccess>(url)).data
            const sensorGraph: SensorGraphData = {
                    temp: result.temp,
                    hum: result.hum,
                    co2: result.co2,
                    light: result.light,
                    noise: result.noise,
            }
            const ret: SensorGraphDataState = {
                sensorGraph: sensorGraph,
                isDataexist: true,
            }
            return ret;
        } catch(err){
            console.log(err)
        }
    }
)