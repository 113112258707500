import styled from "styled-components"

export const EvaluationWrapper = styled.div`
  position: relative;
  height: 21vw;
  line-height: 1.1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #666;
  @media screen and (orientation: portrait) {
    height: 22vw;
    width: 19vw;
  }
  @media screen and (orientation: landscape) {
    height: 9vw;
    width: 9vw;
  }
  & > img {
    @media screen and (orientation: portrait) {
      width: 12vw;
      height: 12vw;
    }
    @media screen and (orientation: landscape) {
      width: 4.5vw;
      height: 4.5vw;
    }
  }
`
