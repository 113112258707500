import { ModalWrapper, ModalTab, ModalHeader, FooterWrapper, FooterNavWrapper, FooterMenuWrapper } from "../ModalBlock/styledComponents"
import { NavBackBtnImage, NavForwardBtnImage, MenuBtnImage} from "../../../style/image"
import ModalGraphContent from "../../Molecules/ModalGraphContent"
import { CloseBtnImage } from "../../../style/image"
import React, { useState } from 'react'

const ModalGraphBlock:React.FC<{dateData: string, open: Boolean, sensors: string[]}> = (props) => {

    const [isOpen, setIsOpen] = useState(props.open);
    const [isInit, setIsInit] = useState(true);
    const [modalScroll, setModalScroll] = useState(0); //モーダルを開いた時のスクロール値を取っておく
    const toggle = () => {
        setIsInit(false);
        if (!isOpen) {
            setModalScroll(window.scrollY);
            let body = document.getElementsByTagName("body");
            body[0].style.top = -window.scrollY + "px";
            body[0].classList.add("modal-opened");
        } else {
            let body = document.getElementsByTagName("body");
            body[0].style.top = "0px";
            body[0].classList.remove("modal-opened");
            window.scrollTo(0, modalScroll);
            setModalScroll(0);
        }
        setIsOpen(!isOpen);
    }
    const hide = () => {
        setIsInit(false);
        let body = document.getElementsByTagName("body");
        body[0].style.top = "0px";
        body[0].classList.remove("modal-opened");
        window.scrollTo(0, modalScroll);
        setModalScroll(0);
        setIsOpen(false);
    }

    const dummyHandler = () => {
        return;
    }

    return (
        <div>
            <ModalWrapper className={`bkgnd-${isOpen ? 'open' : isInit ? 'init' : 'close'}`}>
                <ModalTab className={`tab-${isOpen ? 'open' : isInit ? 'init' : 'close'}`}>
                    <ModalHeader>
                        <CloseBtnImage onClick={hide} />
                    </ModalHeader>
                    <ModalGraphContent dateData={props.dateData} sensors={props.sensors} open={isOpen} />
                </ModalTab>
            </ModalWrapper>

            <FooterWrapper>
                <FooterNavWrapper>
                    <NavBackBtnImage onClick={dummyHandler}/>
                    <NavForwardBtnImage onClick={dummyHandler} />
                </FooterNavWrapper>
                <FooterMenuWrapper onClick={toggle}>
                    <MenuBtnImage />
                </FooterMenuWrapper>
            </FooterWrapper>
        </div>
    )
}

export default ModalGraphBlock
