import { createSlice } from '@reduxjs/toolkit'

import { EvaluationDataState, FetchEvaluationDataSuccess } from '../../states/utilState'
import { fetchEvaluationData } from './asyncAction'

export const initialEvaluationState: EvaluationDataState = {
    evaluations: [],
    isDataexist: false
}

const evaluationDataSlice = createSlice({
    name: 'evaluationData',
    initialState: initialEvaluationState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEvaluationData.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
                state.evaluations = action.payload.evaluations.map((data: FetchEvaluationDataSuccess)=>{
                    return {
                        placeName: data.placeName,
                        good: data.good,
                        normal: data.normal,
                        poor: data.poor,
                        available: data.available,
                    }
                })
                state.isDataexist = true
            }
        })
    }
})


// eslint-disable-next-line
export const {} = evaluationDataSlice.actions

export default evaluationDataSlice.reducer

