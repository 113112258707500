import styled from "styled-components"

export const ModalGraphWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
`

export const ModalGraphDateTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  text-align: center;
  margin: 0;
  color: #666;
  font-weight: 500;
  line-height: 1;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) {
    height: 7vh;
    font-size: 7.5vw;
  }
  @media screen and (orientation: landscape) {
    height: 12vh;
    font-size: 3.75vw;
  }
  & > img.end {
    visibility: hidden;
  }
  & > img {
    display: flex;
    align-content: center;
    border-style: none;
    visibility: visible;
    @media screen and (orientation: portrait) {
      height: 5vh;
      padding: 0 3vw;
    }
    @media screen and (orientation: landscape) {
      height: 8vh;
      padding: 0 1.5vw;
    }
  }
`

export const ModalGraphOuterContainer = styled.div`
  width: 100vw;
  overflow-y: auto;
  @media screen and (orientation: portrait) {
    max-height: 90vh;
  }
  @media screen and (orientation: landscape) {
    max-height: 60vh;
  }
`

export const ModalGraphContainer = styled.div`
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  align-items: flex-start;
  @media screen and (orientation: portrait) {
    width: 84vw;
    padding: 0 8vw;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  @media screen and (orientation: landscape) {
    width: 90vw;
    padding: 0 5vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const ModalGraphInnerContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  display: flex;
  align-items: flex-start;
  z-index: 1;
  @media screen and (orientation: portrait) {
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 84vw;
  }
  @media screen and (orientation: landscape) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    width: 42vw;
  }
`

export const ModalGraphTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  color: #666;
  font-weight: 700;
  line-height: 1;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) {
    padding: 1.5vh 0 1vh;
    font-size: 4.2vw;
  }
  @media screen and (orientation: landscape) {
    padding: 2vh 0 2vh;
    font-size: 2.4vw;
  }
`

export const ModalTarget = styled.div`
  display: flex;
  width: 100%;
  color: #666;
  text-align: center;
  font-weight: 500;
  line-height: 1;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) {
    padding: 0.5vh 0;
    font-size: 3.6vw;
  }
  @media screen and (orientation: landscape) {
    padding: 1vh 0 1vh;
    font-size: 1.9vw;
  }
  & > span {
    padding: 0 0.5vw;
  }
`

export const ModalGraphFrame = styled.div`
  display: flex;
  width: 100%;
  color: #666;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) {
    height: 15vh;
    padding: 0.5vh 0;
    font-size: 3vw;
  }
  @media screen and (orientation: landscape) {
    height: 30vh;
    padding: 1vh 0;
    font-size: 1vw;
  }
`

export const ModalSelector = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`

export const ModalSelectorButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid slategray;
  color: slategray;
  background-color: white;
  @media screen and (orientation: portrait) {
    padding: 0.5vh 3vw;
    margin: 1vh 0;
    font-size: 3.6vw;
    border-radius: 1.5vh;
  }
  @media screen and (orientation: landscape) {
    padding: 1vh 1.5vw;
    margin: 2vh 0;
    font-size: 1.8vw;
    border-radius: 3vh;
  }
  &.selected {
    background-color: slategray;
    color: white;
  }
`

export const ModalFooterSpacer = styled.div`
  display: flex;
  width: 84vw;
  @media screen and (orientation: portrait) {
    height: calc(14vh + 4px);
  }
  @media screen and (orientation: landscape) {
    height: calc(20vh + 4px);
  }
`

export const ModalGraph = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
`

export const ModalData = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
`

export const ModalMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  height: 7vh;
  width: 100vw;
  border-bottom: 1px solid #ccc;
`

export const ModalMenuIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 7vh;
  width: 15vw;
  & img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    height: 5vh;
    padding: 1vh 0;
  }
`
export const ModalMenuText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 7vh;
  width: 82vw;
  font-size: 4vw;
  align-items: center;
`
