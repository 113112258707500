import { Store, combineReducers } from 'redux'
import officeDataSlice, {initialState as officeDataInitialState } from "./officeData/slice"
import minMaxDataSlice, {initialMinMaxState as minMaxDataInitialState } from "./minMaxData/slice"
import evaluationDataSlice, {initialEvaluationState as evaluationDataInitialState } from "./evaluationData/slice"
import sensorGraphDataSlice, {initialSensorGraphDataState as sensorGraphDataInitialState } from "./sensorGraphData/slice"

export const rootReducer = combineReducers({
    officeData: officeDataSlice,
    minMaxData: minMaxDataSlice,
    evaluationData: evaluationDataSlice,
    sensorGraphData: sensorGraphDataSlice,
})

export const preloadedState = () => {
    return {
        officeData: officeDataInitialState,
        minMaxData: minMaxDataInitialState,
        evaluationData: evaluationDataInitialState,
        sensorGraphData: sensorGraphDataInitialState,
    }
}

export type StoreState = ReturnType<typeof preloadedState>

export type ReduxStore = Store<StoreState>