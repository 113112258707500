import styled from "styled-components"

export const LoginFormBlockWrapper = styled.div`
  box-sizing: content-box;
  position: absolute;
  margin: 0;
  padding: 2vw 0;
  z-index: 1001;
  font-weight: 500;
  text-align: center;
  background-color: rgba(95, 158, 160, 0.2);
  border-radius: 2vw;
  color: #666;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  box-shadow: 0px 1px 1px 1px #eee;
  @media screen and (orientation: portrait) {
    top: 25vh;
    left: 8vw;
    width: 84vw;
    height: 70vw;
    font-size: 4vw;
  }
  @media screen and (orientation: landscape) {
    top: 30vh;
    left: 15vw;
    width: 70vw;
    height: 70vw;
    font-size: 4vw;
  }
`

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6vw;
  width: 100%;
  padding: 1vw 3vw;
  margin: 0;
  text-align: left;
  & input[type="text"], input[type="password"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 4vw;
    width: 100%;
    padding: 0.75vw;
    margin: 0;
    text-align: left;
    border-radius: 1vw;
    border-color: #ccc;
  }
  & input[type="submit"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6vw;
    width: 50%;
    padding: 0.75vw;
    margin: 0 25%;
    text-align: center;
    justify-content: center;
    border-radius: 2vw;
    background-color: cadetblue;
    box-shadow: 0px 1px 1px 1px #eee;
    font-size: 3vw;
    font-weight: 500;
    color: #333;
  }
`
