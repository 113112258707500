import { createSlice } from '@reduxjs/toolkit'

import { MinMaxTempDataState } from '../../states/utilState'
import { fetchMinMaxTempData } from './asyncAction'

export const initialMinMaxState: MinMaxTempDataState = {
    temp: { addr: "", min: 0, max: 0 },
    isDataexist: false
}

const minMaxDataSlice = createSlice({
    name: 'minMaxData',
    initialState: initialMinMaxState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMinMaxTempData.fulfilled, (state, action) => {
            console.log("call action");
            console.log(action);
            if (action.payload !== undefined) {
                // console.log("ineed");
                state.temp = action.payload
                state.isDataexist = true
            }
        })
    }
})


// eslint-disable-next-line
export const {} = minMaxDataSlice.actions

export default minMaxDataSlice.reducer

