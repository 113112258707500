import styled from 'styled-components';
export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0;
  margin: 0;
  font-family: 'M PLUS Rounded 1c', helvetica;
  overflow-y: scroll;
  @media screen and (orientation: portrait) {
    min-height: 88vh;
  }
  @media screen and (orientation: landscape) {
    min-height: 88vh;
  }
`

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 29vh;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  background-color: white;
  @media screen and (orientation: portrait) {
    height: 29vh;
  }
  @media screen and (orientation: landscape) {
    height: 31vh;
  }
`

export const LogoAreaWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 33.3vw;
  height: 7vh;
`

export const WeatherWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2vh 2vw 0 0;
  width: 66.7vw;
  margin: 0;
  color: cadetblue;
  text-align: right;
  font-weight: 600;
  line-height: 1;
  @media screen and (orientation: portrait) {
    height: 2.4vh;
    font-size: 4.2vw;
  }
  @media screen and (orientation: landscape) {
    height: 4.5vh;
    font-size: 2.1vw;
  }
`

export const TabContainer = styled.div`
  box-sizing: content-box;
  position: absolute;
  width: 100vw;
  margin: 0;
  padding: 0;
  border-bottom: #ccc solid 4px;
  z-index: 1001;
  @media screen and (orientation: portrait) {
    top: 8.5vh;
    height: 4vh;
  }
  @media screen and (orientation: landscape) {
    top: 10.5vh;
    height: 7vh;
  }
`

export const Tab = styled.div`
  box-sizing: content-box;
  position: absolute;
  width: 40vw;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 400;
  color: #ccc;
  border-bottom: #ccc solid 4px;
  margin-bottom: -4px;
  z-index: 1002;
  @media screen and (orientation: portrait) {
    height: 5vh;
    font-size: 4.2vw;
  }
  @media screen and (orientation: landscape) {
    height: 7vh;
    font-size: 2.1vw;
  }
  &.left {
    left: 5vw;
  }
  &.right {
    right: 5vw;
  }
  &.active {
    color: #666;
    border-bottom: cadetblue solid 4px;
  }

`
export const EnvDateWrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  position: absolute;
  @media screen and (orientation: portrait) {
    top: 17vh;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  @media screen and (orientation: landscape) {
    top: 21vh;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`
export const EnvTitleWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 0;
  margin: 0;
  color: #666;
  font-size: 8vw;
  font-weight: 500;
  line-height: 1;
  @media screen and (orientation: portrait) {
    left: 0;
    width: 100vw;
    top: 0;
    height: 5vh;
    font-size: 7.5vw;
    text-align: center;
  }
  @media screen and (orientation: landscape) {
    left: 0;
    top: 0;
    height: 2.8vw;
    font-size: 2.8vw;
    padding: 0 1.5vw;
  }
`
