import React from 'react'

import LoginTemplate from '../Template/LoginTemplate/LoginTemplate'

const Login: React.FC = () => {
    return (
        <LoginTemplate>
        </LoginTemplate>
    )
}

export default Login