import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { rootReducer, preloadedState } from '../reducer'

const createStore = () => {
    const middlewareList = process.env.NODE_ENV !== 'production' ? [...getDefaultMiddleware(), logger] :[...getDefaultMiddleware()]

    return configureStore({
        reducer: rootReducer,
        middleware: middlewareList,
        // devTools: process.env.NODE_ENV !== 'production',
        preloadedState: preloadedState(),
    })
}

export default createStore