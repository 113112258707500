import styled from "styled-components"

export const CalendarContainerWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  max-height: 64vh;
  left: 3vw;
  width: 94vw;
  display: flex;
  padding: 0;
  flex-direction: column;
  flex-wrap: wrap;
`

export const CalendarScrollWrapper = styled.div`
  position: relative;
  top: 0;
`

export const CalendarContentWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 94vw;
  display: flex;
  padding: 0;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  @media screen and (orientation: portrait) {
    flex-direction: column;
  }
  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
`

export const MonthSelectWrapper = styled.div`
  width: 84vw;
  left: 5vw;
  height: 6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: 6vw;
  font-weight: 500;
  padding: 0;
  margin: 0;
  line-height: 1;
  @media screen and (orientation: portrait) {
    position: relative;
    top: 0;
    width: 84vw;
    left: 5vw;
    height: 6vw;
    font-size: 6vw;
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (orientation: landscape) {
    position: sticky;
    top: 0;
    padding-top: 4vh;
    width: 28vw;
    left: 5vw;
    height: 2.8vw;
    font-size: 2.8vw;
    flex-direction: column;
    justify-content: space-around;
  }

`
export const MonthWrapper = styled.div`
  height: 6vw;
  padding: 0;
  margin: 0;
  color: #666;
`

export const MonthSelectorWrapper = styled.div`
  width: 18vw;
  right: 0;
  height: 6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  font-size: 6vw;
  font-weight: 500;
  padding: 0;
  margin: 0;
  & img.end {
    visibility: hidden;
  }
  & img {
    @media screen and (orientation: portrait) {
      width: 3.5vh;
      border-radius: 1.75vh;
    }
    @media screen and (orientation: landscape) {
      height: 7vh;
      border-radius: 3.5vh;
    }
    visibility: visible;
    box-shadow: 0px 1px 1px 1px #eee;
    background-color: transparent;
  }
  & img:active:hover {
    background-color: rgba(224, 224, 224, 0.3);
  }
`

export const CalendarContainerInnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
  @media screen and (orientation: portrait) {
    top: 4vw;
    width: 84vw;
    max-height: 84vw;
    left: 5vw;
    font-size: 4vw;
  }
  @media screen and (orientation: landscape) {
    top: 0;
    width: 49vw;
    max-height: 49vw;
    left: 11vw;
    font-size: 2vw;
  }
`