import React, { useEffect, useState } from "react";

export interface AuthContextInterface {
  isAuth: boolean,
  setState: (value: boolean) => void,
}

export type AuthInfo = {
  company: string;
  userid: string;
};

export type LoginInfo = {
  isLogin: boolean;
};

export interface SensorListContextInterface {
  sensorNames: string[],
  setState: (value: string[]) => void,
}

export type SensorListInfo = {
  sensorNames: string[];
};

// ログイン状態のContext
//export const LoggedInContext = React.createContext<boolean>(false);
export const LoggedInContext = React.createContext<
  [LoginInfo, React.Dispatch<React.SetStateAction<LoginInfo>>]
>([{ isLogin: false }, () => {}]);

// 認証情報と認証情報セットのContext
export const AuthInfoContext = React.createContext<
  [AuthInfo, React.Dispatch<React.SetStateAction<AuthInfo>>]
>([{ company: "", userid: "" }, () => {}]);

// センサー名リストのContext
//export const SensorListContext = React.createContext<string[]>([]);
export const SensorListContext = React.createContext<
  [SensorListInfo, React.Dispatch<React.SetStateAction<SensorListInfo>>]
>([{ sensorNames: [] }, () => {}]);

export const AuthContextProvider: React.FC<{}> = (props) => {
  // stateの定義
  const [loggedIn, setLoggedIn] = useState<LoginInfo>({ isLogin: false });
  const [authInfo, setAuthInfo] = useState<AuthInfo>({ company: "", userid: "" });
  const [sensorList, setSensorList] = useState<SensorListInfo>({ sensorNames: [] });

  // authInfoのバリデーション
  useEffect(() => {
    // authInfoに正しく値がセットされているかどうかをチェック
    if (authInfo?.userid) {
      setLoggedIn({isLogin: true});
      console.log("login")
    } else {
      setLoggedIn({isLogin: false});
      console.log("logout")
    }
    console.log("debug")
  }, [authInfo]);

  return (
    <LoggedInContext.Provider value={[loggedIn, setLoggedIn]}>
      <AuthInfoContext.Provider value={[authInfo, setAuthInfo]}>
        <SensorListContext.Provider value={[sensorList, setSensorList]}>
          {props.children}
        </SensorListContext.Provider>
      </AuthInfoContext.Provider>
    </LoggedInContext.Provider>
  );
};
