import React, { useContext } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from "react-router-dom"
import { LoggedInContext, AuthInfoContext } from "../../../context/AuthContextProvider";
import {
    LoginFormBlockWrapper, LoginFormWrapper
} from "./styledComponents"

type Inputs = {
    company: string;
    userid: string;
    password: string;
};

const LoginFormBlock:React.FC = () => {
    /* eslint-disable */
    const [loggedIn, setLoggedIn] = useContext(LoggedInContext);
    const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
    /* eslint-enable */

    const fakeAuth = () => {
        return new Promise<number>(resolve => {
            setTimeout(() => {
                resolve(0)
            }, 250)
        })
    }

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setAuthInfo({company: data.company, userid: data.userid});
        setLoggedIn({isLogin: true});
        await fakeAuth();
        navigate("/");
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LoginFormBlockWrapper>

                <LoginFormWrapper>
                    会社コード
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="text" {...register('company', { required: true })} />
                </LoginFormWrapper>
                {errors.company && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}
                <LoginFormWrapper>
                    ID
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="text" {...register('userid', { required: true })} />
                </LoginFormWrapper>
                {errors.userid && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}

                <LoginFormWrapper>
                    パスワード
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="password" {...register('password', { required: true })} />
                </LoginFormWrapper>
                {errors.password && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}

                <LoginFormWrapper>
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="submit" value="ログイン" />
                </LoginFormWrapper>

            </LoginFormBlockWrapper>
        </form>
    )
}

export default LoginFormBlock

