import React from "react";
import {
    CalendarCellWrapper
} from "./styledComponents"

const CalendarCell:React.FC<{data?: string, dow: number, attrDate?: string, onDateClick?:(event:React.MouseEvent<HTMLDivElement>)=>void}> = (props) => {
    const dow: string[] = ["日", "月", "火", "水", "木", "金", "土"];
    let cls: string = "";
    const today = new Date();
    const todayStr = today.getFullYear() + "-" + ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);

    if (props.data === undefined) {
        return (
            <CalendarCellWrapper></CalendarCellWrapper>
        )
    } else if (props.data === "week") {
        if (props.dow === 0) {
            cls = "sunday";
        } else if (props.dow === 6) {
            cls = "saturday";
        }
        return (
            <CalendarCellWrapper className={cls}>{dow[props.dow]}</CalendarCellWrapper>
        )
    } else if (props.attrDate !== undefined && props.attrDate > todayStr) {
        if (props.dow === 0) {
            cls = "f sunday";
        } else if (props.dow === 6) {
            cls = "f saturday";
        } else {
            cls = "f";
        }
        return (
            <CalendarCellWrapper className={cls}>{props.data}</CalendarCellWrapper>
        )
    } else {
        if (props.dow === 0) {
            cls = "m sunday";
        } else if (props.dow === 6) {
            cls = "m saturday";
        } else {
            cls = "m";
        }
        return (
            <CalendarCellWrapper className={cls} data-date={props.attrDate} onClick={props.onDateClick}>{props.data}</CalendarCellWrapper>
        )
    }
}

export default CalendarCell

