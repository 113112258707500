import styled from "styled-components"

export const ModalMenuWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
  height: calc(81vh - 8px);
`

export const ModalMenu = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  & a, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
  }
`

export const ModalData = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
`

export const ModalMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  width: 100vw;
  border-bottom: 1px solid #ccc;
  @media screen and (orientation: portrait) {
    height: 12vw;
  }
  @media screen and (orientation: landscape) {
    height: 5vw;
  }

`

export const ModalMenuIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (orientation: portrait) {
    height: 12vw;
    width: 12vw;
  }
  @media screen and (orientation: landscape) {
    height: 5vw;
    width: 5vw;
  }
  & img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    @media screen and (orientation: portrait) {
      height: 6vw;
      padding: 3vw 0;
    }
    @media screen and (orientation: landscape) {
      height: 3vw;
      padding: 1vw 0;
    }
  }
`
export const ModalMenuText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (orientation: portrait) {
    width: 82vw;
    font-size: 5vw;
  }
  @media screen and (orientation: landscape) {
    width: 90vw;
    font-size: 2.5vw;
  }
`
