import styled, {keyframes} from "styled-components"

const bkgndClose = keyframes`
  0% {
    visibility: display;
    height: 100vh;
  }
  100% {
    visibility: hidden;
    height: 0vh;
  }
`

export const ModalWrapper = styled.div`
  min-height: 100%;
  overscroll-behavior: none;
  background-color: rgba(224, 224, 224, 0.8);
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
  &.bkgnd-init {
    visibility: hidden;
    height: 0vh;
  }
  &.bkgnd-open {
    visibility: visible;
    height: 100vh;
  }
  &.bkgnd-close {
    animation: ${bkgndClose} 0s linear 0.5s forwards;
  }
  &.bkgnd-close-immediately {
    animation: ${bkgndClose} 0s linear 0s forwards;
  }
`

const tabOpen = keyframes`
  0% {
    top: 93vh;
  }
  100% {
    top: 1vh;
  }
`

const tabClose = keyframes`
  0% {
    top: 1vh;
  }
  100% {
    top: 93vh;
  }
`

export const ModalTab = styled.div`
  position: fixed;
  min-height: calc(88vh - 4px);
  overscroll-behavior: none;
  background-color: white;
  width: 100vw;
  left: 0;
  height: calc(88vh - 4px);
  display: block;
  border-radius: 2vw 2vw 0 0;
  &.tab-init {
    top: 93vh;
  }
  &.tab-open {
    animation: ${tabOpen} 0.5s ease-in-out 0s both;
  }
  &.tab-close {
    animation: ${tabClose} 0.5s ease-in-out 0s both;
  }
  &.tab-close-immediately {
    animation: ${tabClose} 0s ease-in-out 0s both;
  }
`

export const ModalHeader = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
  border-bottom: #ccc solid 4px;
  border-radius: 2vw 2vw 0 0;
  @media screen and (orientation: portrait) {
    height: 7vh;
  }
  @media screen and (orientation: landscape) {
    height: 12vh;
  }
  & img {
    position: relative;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    float: right;
    @media screen and (orientation: portrait) {
      width: 5vh;
      height: 5vh;
      padding: 1vh 2vw;
    }
    @media screen and (orientation: landscape) {
      width: 8vh;
      height: 8vh;
      padding: 2vh 2vw;
    }
  }
`

export const ModalTabContent = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
  height: calc(81vh - 8px);
`

export const ModalMenu = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
`

export const ModalData = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: transparent;
`

export const ModalMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  width: 100vw;
  border-bottom: 1px solid #ccc;
  @media screen and (orientation: portrait) {
    height: 7vh;
  }
  @media screen and (orientation: landscape) {
    height: 14vh;
  }
`

export const ModalMenuIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (orientation: portrait) {
    height: 7vh;
    width: 15vw;
  }
  @media screen and (orientation: landscape) {
    height: 14vh;
    width: 25vw;
  }
  & img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    @media screen and (orientation: portrait) {
      height: 5vh;
      padding: 1vh 0;
    }
    @media screen and (orientation: landscape) {
      height: 10vh;
      padding: 2vh 0;
    }
  }
`
export const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  z-index: 2000;
  border-top: #ccc solid 4px;
  background-color: white;
  @media screen and (orientation: portrait) {
    height: 7vh;
  }
  @media screen and (orientation: landscape) {
    height: 12vh;
  }
  & img {
    border-style: none;
    margin: 0 1vw;
    @media screen and (orientation: portrait) {
      height: 5vh;
    }
    @media screen and (orientation: landscape) {
      height: 8vh;
    }
  }
`

export const FooterNavWrapper = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  width: 25vw;
  padding-left: 5vw;
  padding-right: 5vw;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) {
    height: 7vh;
  }
  @media screen and (orientation: landscape) {
    height: 12vh;
  }
`

export const FooterMenuWrapper = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  width: 25vw;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) {
    height: 7vh;
  }
  @media screen and (orientation: landscape) {
    height: 12vh;
  }
`