import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import {FetchOfficeDataSuccess} from '../../states/utilState'

export const fetchOfficeData = createAsyncThunk(
    'officeData/fetchOfficeData',
    async () => {
        const url: string = "https://jsq305drm9.execute-api.ap-northeast-1.amazonaws.com/version1/latest/3"
        try{
            return await (await axios.get<FetchOfficeDataSuccess>(url)).data
        } catch(err){
            console.log(err)
        }
    }
)