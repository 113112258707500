import styled from "styled-components"

export const ContainerFrame = styled.div`
  position: absolute;
  overflow-y: auto;
  width: 100%;
  @media screen and (orientation: portrait) {
    bottom: calc(7vh + 4px);
  }
  @media screen and (orientation: landscape) {
    bottom: calc(12vh + 4px);
  }
`
export const ContainerWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 3vw;
  width: 94vw;
  display: flex;
  padding: 0;
  flex-direction: column;
  flex-wrap: wrap;
`

export const ContainerScrollWrapper = styled.div`
  position: relative;
  top: 0;
`