import { useState, useContext } from 'react'
import Login from "./components/Pages/Login"
import Main from "./components/Pages/Main"
import Detail from "./components/Pages/Detail"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoggedInContext, SensorListContext } from "./context/AuthContextProvider";

function App() {
  const [date] = useState(new Date());
  /* eslint-disable */
  const [isLoggedIn, setLoggedIn] = useContext(LoggedInContext);
  const [sensorList, setSensorList] = useContext(SensorListContext);
  /* eslint-enable */

  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main login={isLoggedIn} sensors={sensorList} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/detail" element={<Detail date={date} login={isLoggedIn} sensors={sensorList} />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
