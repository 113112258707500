import CalendarCell from "../../Atoms/CalendarCell"

const CalendarWeek: React.FC = () => {
    return (<>
        {makeCalendarWeek()}
    </>)
}

const makeCalendarWeek = () => {
    const dow: string[] = ["日", "月", "火", "水", "木", "金", "土"];
    return dow.map((str, index) => {
        return (
            <CalendarCell data="week" dow={index} key={index}>{str}</CalendarCell>
        )
    })
}
export default CalendarWeek

