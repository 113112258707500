import styled from "styled-components"

export const CalendarCellWrapper = styled.div`
  width: calc(12vw - 2px);
  height: calc(12vw - 2px);
  padding: 0;
  background-color: white;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px white solid;
  @media screen and (orientation: portrait) {
    width: calc(12vw - 2px);
    height: calc(12vw - 2px);
  }
  @media screen and (orientation: landscape) {
    width: calc(7vw - 2px);
    height: calc(7vw - 2px);
  }

  &.m {
    background-color: paleturquoise;
  }
  &.m:active:hover {
    background-color: turquoise;
  }
  &.f {
    background-color: azure;
  }
  &.sunday {
    color: red;
  }
  &.saturday {
    color: blue;
  }
`
