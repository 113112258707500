import {
    SensorNameWrapper
} from "./styledComponents"

const SensorName:React.FC<{placeName: string}> = (props) => {
    return (
        <SensorNameWrapper>{props.placeName}</SensorNameWrapper>
    )
}

export default SensorName

