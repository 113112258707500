import { createSlice } from '@reduxjs/toolkit'
import { FetchSensorData, OfficeDataState, TempProps, HumProps } from '../../states/utilState'
import { fetchOfficeData } from './asyncAction'

export const initialState: OfficeDataState = {
    sensorDatas: [],
    weather: 0,
    timeZone: 0,
    isDataexist: false
}

const officeDataSlice = createSlice({
    name: 'officeData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOfficeData.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
                state.sensorDatas = action.payload.sensorInfo.map((data)=>{
                    return {
                        placeName: data.placeName,
                        temp: getTempProp(data),
                        hum: getHumProp(data),
                        comfort: data.comfort,
                        volume: data.volume,
                        light: data.light,
                        co2: data.co2,
                        evaluation: data.evaluation,
                    }
                })
                state.weather = action.payload.weather
                state.timeZone = action.payload.timeZone
                state.isDataexist = true
            }
        })
    }
})

type TempPropScore = {
    (data: FetchSensorData): TempProps;
}

const getTempProp: TempPropScore = (data: FetchSensorData) => {
    let prop: TempProps = {
        temp: Math.round(data.temp),
        level: 1,
        msg: "普通"
    };

    if (Math.round(data.temp) <= 17) {
        prop.level = 0;
        prop.msg = "寒い";
    } else if (28 <= Math.round(data.temp)) {
        prop.level = 2;
        prop.msg = "暑い";
    } 

    return prop;
}

type HumPropScore = {
    (data: FetchSensorData): HumProps;
}

const getHumProp: HumPropScore = (data: FetchSensorData) => {
    let prop: HumProps = {
        hum: data.hum,
        level: 1,
        msg: "普通"
    };

    if (data.hum < 40) {
        prop.level = 0;
        prop.msg = "乾燥";
    } else if (70 < data.hum) {
        prop.level = 2;
        prop.msg = "多湿";
    } 

    return prop;
}

// eslint-disable-next-line
export const {} = officeDataSlice.actions

export default officeDataSlice.reducer

