import React from 'react';
import { EvaluationIconImage, TempIconImage, HumIconImage, CO2IconImage, LightIconImage, SoundIconImage } from '../../../style/image';

export const EvaluationIcon: React.FC<{level: number}> = (props) => {
    return (
        <EvaluationIconImage level={props.level} />
    );
}

export const TempIcon: React.FC<{level: number}> = (props) => {
    return (
        <TempIconImage level={props.level} />
    );
}

export const HumIcon: React.FC<{level: number}> = (props) => {
    return (
        <HumIconImage level={props.level} />
    );
}

export const CO2Icon: React.FC<{level: number}> = (props) => {
    return (
        <CO2IconImage level={props.level} />
    );
}

export const LightIcon: React.FC<{level: number}> = (props) => {
    return (
        <LightIconImage level={props.level} />
    );
}

export const SoundIcon: React.FC<{level: number}> = (props) => {
    return (
        <SoundIconImage level={props.level} />
    );
}
