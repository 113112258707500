import { createSlice } from '@reduxjs/toolkit'

import { SensorGraphDataState } from '../../states/utilState'
import { fetchSensorGraphData } from './asyncAction'

export const initialSensorGraphDataState: SensorGraphDataState = {
    sensorGraph: {
        temp: [],
        hum: [],
        co2: [],
        light: [],
        noise: [],
    },
    isDataexist: false
}

const sensorGraphDataSlice = createSlice({
    name: 'sensorGraphData',
    initialState: initialSensorGraphDataState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSensorGraphData.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
                state.sensorGraph.temp = action.payload.sensorGraph.temp;
                state.sensorGraph.hum = action.payload.sensorGraph.hum;
                state.sensorGraph.co2 = action.payload.sensorGraph.co2;
                state.sensorGraph.light = action.payload.sensorGraph.light;
                state.sensorGraph.noise = action.payload.sensorGraph.noise;
                state.isDataexist = true
            }
        })
    }
})


// eslint-disable-next-line
export const {} = sensorGraphDataSlice.actions

export default sensorGraphDataSlice.reducer

