import styled from "styled-components"

export const DisplayDataBlockWrapper = styled.div`
  position: relative;
  line-height: 1.1;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  display: flex;
  font-weight: 600;
  color: #666;
  flex-direction: row;
  align-items: center;
  align-content: center;
  @media screen and (orientation: portrait) {
    height: 18vw;
    width: 50vw;
    font-size: 4vw;
    padding 2vw 0;
  }
  @media screen and (orientation: landscape) {
    height: 8vw;
    width: 60vw;
    font-size: 2.2vw;
    padding 0.5vw 0;
  }
`

export const DisplayDataWrapper = styled.div`
  position: relative;
  line-height: 1.1;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #666;
  top: 0;
  width: 25vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media screen and (orientation: portrait) {
    width: 25vw;
    font-size: 4vw;
    height: 6vw;
  }
  @media screen and (orientation: landscape) {
    width: 20vw;
    font-size: 2.2vw;
    height: 4vw;
  }
`
export const SensorIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (orientation: portrait) {
    width: 8vw;
    height: 6vw;
  }
  @media screen and (orientation: landscape) {
    width: 5vw;
    height: 4vw;
  }
  & > img {
    @media screen and (orientation: portrait) {
      height: 5vw;
      width: 5vw;
      padding: 0.5vw 0;
    }
    @media screen and (orientation: landscape) {
      width: 2.8vw;
      height: 2.8vw;
      padding: 0.2vw 0;
    }
  }
`

export const SensorDataWrapper = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  @media screen and (orientation: portrait) {
    width: 17vw;
    font-size: 4vw;
    height: 4vw;
    padding: 1vw 0;
  }
  @media screen and (orientation: landscape) {
    width: 15vw;
    font-size: 2.2vw;
    height: 2.2vw;
    padding: 0.9vw 0;
  }
  & > span.unit {
    padding-left: 0.3vw;
    font-weight: 500;
      @media screen and (orientation: portrait) {
        padding-top: 0.5vw;
        font-size: 3.5vw;
      }
      @media screen and (orientation: landscape) {
        padding-top: 0.4vw;
        font-size: 1.8vw;
      }
    }
`

